<template>
  <div class="card half">
    <div class="card-header">Log In</div>
    <div class="card-body">
      <div class="card-text">
        <form>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" placeholder="name@example.com" v-model="form.Email" />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input type="password" class="form-control" id="password" v-model="form.Password" />
          </div>
        </form>
      </div>
    </div>
    <div class="card-footer ">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <router-link to="/passwordrecovery" class="align-middle">Forgot Password</router-link>
        </div>
        <div class="col-xs-12 col-sm-6 text-right">
          <button type="button" class="btn btn-primary" @click="logIn" :disabled="loading">Login</button>
          <img v-if="loading" src="/img/wait_circle_25.GIF" alt="Please wait..." />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _userService from "@/services/userService";
//https://vuelidate.js.org/#sub-builtin-validators
import { required, minLength, email } from "vuelidate/lib/validators";
import * as _validation from "@/utility/validation";

export default {
  name: "vLogIn",
  props: {},
  components: {},
  data: () => ({
    form: {
      Email: "",
      Password: "",
    },
    loading: false,
  }),
  methods: {
    logIn() {
      this.loading = true;
      _userService.logIn(this.form.Email, this.form.Password).then((response) => {
        if (response.Success) {
          this.$router.push("/");
        } else {
          alert(response.FailureInformation);
        }
        this.loading = false;
      });
    },
    formIsValid(f) {
      return _validation.formIsValid(f);
    },
    validationState(p) {
      return _validation.validationState(p);
    },
    validationErrorMessage(p) {
      return _validation.validationErrorMessage(p);
    },
  },
  validations: {
    form: {
      Email: { required, email },
      Password: { required, minLength: minLength(8) },
    },
  },
  computed: {},
  watch: {},
  created: function() {
    /*Called synchronously after the instance is created.
      At this stage, the instance has finished processing the options
      which means the following have been set up:
      data observation, computed properties, methods, watch/event callbacks.
      However, the mounting phase has not been started,
      and the $el property will not be available yet*/
  },
  mounted: function() {
    /*Called after the instance has just been mounted
      where el is replaced by the newly created vm.$el.
      If the root instance is mounted to an in-document element,
      vm.$el will also be in-document when mounted is called*/
  },
};
</script>

<style></style>
