export function formIsValid(form) {
	return !form.$invalid;
}

export function validationState(property) {
	return property.$dirty ? !property.$error : null;
}

//https://vuelidate.js.org/#sub-builtin-validators
export function validationErrorMessage(property) {
	if (property.$dirty && !property.$error) return "";
	//not sure why some validation shows up here and not as part of the $params collection..
	if (property.currencyValidation != null && property.currencyValidation == false) return "Currency must be positive number with two decimal places!";
	for (let p in property.$params) {
		let paramName = p;
		let paramValue = property.$params[p];
		for (var v in paramValue) {
			var paramValueName = v;
			var paramValueValue = paramValue[v];
			//console.log(property);
			//console.log(paramName + ":" + paramValueName + ":" + paramValueValue);
			switch (paramName) {
				case "required":
					if (!property.required) return "Required!";
					break;
				case "minLength":
					if (!property.minLength) return "Minimum of " + property.$params.minLength.min + " characters required!";
					break;
				case "email":
					if (!property.email) return "Invalid Email Address!";
					break;
				case "sameAsPassword":
					if (!property.sameAs) return "Passwords Do Not Match!";
					break;
				case "currencyValidation":
					if (!property.currencyValidation) return "Currency must be positive number with two decimal places!";
					break;
				//Add Additional validation messages here....
				default:
					return paramName + " validation message is undefined.";
			}
		}
	}
	return "Unspecified validation violation!";
}
